<template>
    <div class="">
        <Loader v-if="loading" />
        <b-container class="py-5 pt-10p">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-secondary border-0 mb-0 shadow">
                        <b-card-body class="px-lg-5 py-lg-5">
                            <div class="text-center text-muted mb-4">
                                <img src="../assets/img/c-balance.png" />
                            </div>
                            <div class="btn-wrapper text-center py-5">
                                <button
                                    @click="googleSignIn"
                                    class="btn btn-neutral btn-icon w100p"
                                >
                                    <span class="btn-inner--icon">
                                        <img src="img/icons/common/google.svg"/>
                                    </span>
                                    <span class="btn-inner--text">Sign in with Google</span>
                                </button>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import firebase from "firebase";

// import axios from "../utility/axios";
import Loader from "../components/Loader";
import Swal from 'sweetalert2';

export default {
    components: { Loader },
    data() {
        return {
            email: "",
            loading: false,
            allow: false,
        };
    },
    methods: {
        async deleteUser(user) {
            await firebase
                .auth()
                .signOut()
                .then(async () => {
                    console.log("Sign-out successful.");
                    await user.delete().then(function() {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'You aren’t authorized to access this software, please send a request to admin@cbalance.in to request access.',
                        })
                    }).catch(function(error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.message,
                        })
                    });
                })
                .catch((error) => {
                    console.log("Sign-out error happened.", error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.message,
                    })
                });
        },
        async googleSignIn() {
            this.loading = true;
            let provider = new firebase.auth.GoogleAuthProvider();

            let auth = firebase.auth();
            auth.signInWithPopup(provider)
                .then(async (result) => {
                    let verify = await this.verifyUser(result.user.email);
                    if(!verify){
                        console.log("google login inside if", verify);
                        await this.deleteUser(result.user)
                    } else {
                        console.log("google login inside else", verify);
                        await this.$store.commit("setUser", result.user);

                        let accessToken = await auth.currentUser.getIdToken(true);
                        console.log("Access Token form getIdToken(): " + accessToken);
                        await this.$store.commit("setAccessToken", accessToken);

                        this.$router.push("projects");
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message,
                    })
                    console.log(err);
                    this.loading = false;
                });
        },
        async verifyUser(email){
            return await this.axios.get("verifyUser?email=" + email)
                .then(res => {
                    console.log("verifyUser res", res);
                    return true;
                }).catch(err => {
                    console.log("verifyUser err", err);
                    return false;
                })
        }
    },
    created: async function () {},
};
</script>

<style scoped lang="scss">
.pt-10p {
    padding-top: 10% !important;
}
.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.cust-btn {
    color: #fff;
    background-color: #a8ce54;
    border-color: #a8ce54;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.w100p {
    width: 100%;
}
</style>
<style lang="scss">
.swal2-html-container,
.swal2-title{
    color: #000;
}
.swal2-confirm{
    background-color: #4DC0DB !important;
}
.swal2-header{
    display: none;
}
</style>
